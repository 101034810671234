* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

.App {
  margin: 1.2rem;
  overflow: hidden;
}
